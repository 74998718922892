import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import storage from "../common/utils/storage";

var u = navigator.userAgent;
var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
if (isiOS && !window.entryLink) {
  window.entryLink = encodeURIComponent(window.location.href.split("#")[0]);
}

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}

/*
isRoutes: true为必选项
*/
const routes = [
  {
    path: "/",
  },
  {
    path: "/authorize",
    name: "Authorize",
    component: () => import("@/views/Authorize/Authorize"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/entrance",
    name: "Entrance",
    component: () => import("@/views/entrance/entrance"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/login"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/JNJUser",
    name: "JNJUser",
    component: () => import("@/views/JNJUser/JNJUser"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/openJNJUser",
    name: "OpenJNJUser",
    component: () => import("@/views/JNJUser/openJNJUser"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/register/register"),
    meta: {
      navShow: false,
      role: false,
      keepAlive: true,
      isRoutes: true
    }
  },
  {
    path: "/publicRegister",
    name: "PublicRegister",
    component: () => import("@/views/register/publicRegister"),
    meta: {
      navShow: false,
      role: false,
      keepAlive: true,
      isRoutes: true
    }
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("@/views/search/index"),
    meta: {
      navShow: false,
      role: true,
      keepAlive: false,
      isRoutes: true
    }
  },
  {
    path: "/contentPage",
    name: "Content",
    component: () => import("@/views/ContentPage/ContentPage"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/contentyl",
    name: "Contentyl",
    component: () => import("@/views/ContentPage/Contentyl"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/myMain",
    name: "MyMain",
    component: () => import("@/views/mine/myMain"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '个人中心'
    }
  },
  {
    path: "/myFavorite",
    name: "MyFavorite",
    component: () => import("@/views/mine/myFavorite"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '我的点赞'
    }
  },
  {
    path: "/myCollection",
    name: "MyCollection",
    component: () => import("@/views/mine/myCollection"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '我的收藏'
    }
  },
  {
    path: "/myMeeting",
    name: "MyMeeting",
    component: () => import("@/views/mine/myMeeting"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '我的会议'
    }
  },
  {
    path: "/browseRecords",
    name: "BrowseRecords",
    component: () => import("@/views/mine/browseRecords"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
      title: '浏览记录'
    }
  },
  {
    path: "/myQRcode",
    name: "MyQRcode",
    component: () => import("@/views/mine/myQRcode"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/myInvitation",
    name: "MyInvitation",
    component: () => import("@/views/mine/myInvitation"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/default",
    name: "Default",
    component: () => import("@/views/default/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true,
    }
  },
  {
    path: "/termsOfUse",
    name: "TermsOfUse",
    component: () => import("@/views/TermsOfUse/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/privacyPolicy",
    name: "PrivacyPolicy",
    component: () => import("@/views/PrivacyPolicy/index"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true,
    }
  },
  {
    path: "/transition",
    name: "Transition",
    component: () => import("@/views/default/transition"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/channel",
    name: "channel",
    component: () => import("@/views/default/channel"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    path: "/liveEntry",
    name: "LiveEntry",
    component: () => import("@/views/default/liveEntry"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/transferJNJUser",
    name: "TransferJNJUser",
    component: () => import("@/views/default/transferJNJUser"),
    meta: {
      navShow: false,
      role: false,
      isRoutes: true
    }
  },
  {
    // Enterprise线上博物馆
    path: "/enterpriseMuseum",
    name: "EnterpriseMuseum",
    component: () => import("@/views/EnterpriseMuseum/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // EPIC充电站
    path: "/EPICZone",
    name: "EPICZone",
    component: () => import("@/views/EPIC/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },{
    // CNV卒中强声
    path: "/CNVCzqs",
    name: "CNVCzqs",
    component: () => import("@/views/EPIC/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // CNV LIVE
    path: "/CNVLive",
    name: "CNVLive",
    component: () => import("@/views/CNV/live"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // CNV文献解读
    path: "/CNVZone",
    name: "CNVZone",
    component: () => import("@/views/CNV/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 最新推荐列表
    path: "/recommendList",
    name: "RecommendList",
    component: () => import("@/views/CNV/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 产品陈列馆
    path: "/productMuseum",
    name: "ProductMuseum",
    component: () => import("@/views/productMuseum/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 产品详情
    path: "/productDetails",
    name: "ProductDetails",
    component: () => import("@/views/productMuseum/productDetails"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 产品pdf
    path: "/productPdf",
    name: "ProductPdf",
    component: () => import("@/views/productMuseum/pdf"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 弹簧圈栓塞密度计算
    path: "/embolizationCalculation",
    name: "embolizationCalculation",
    component: () => import("@/views/tool/embolizationCalculation"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 病例专区
    path: "/caseZone",
    name: "CaseZone",
    component: () => import("@/views/CaseZone/web3d"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/caseZoneIndex",
    name: "CaseZoneIndex",
    component: () => import("@/views/CaseZone/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    path: "/caseZoneList",
    name: "CaseZoneList",
    component: () => import("@/views/CaseZone/list"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  // 走进专区
  {
    path: "/enteringZone",
    name: "EnteringZone",
    component: () => import("@/views/EnteringZone/index"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  },
  {
    // 强易达选型工具
    path: "/choydarTool",
    name: "choydarTool",
    component: () => import("@/views/tool/choydar"),
    meta: {
      navShow: false,
      role: true,
      isRoutes: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  // console.log(to, from)
  if (to.path != '/authorize') {
    storage.setStore('lastPath', to.fullPath);
  }

  if(from.path != '/' && from.path != '/authorize') {
    sessionStorage.setItem("fromPath", from.path);
  }

  let Openid = null;
  if (process.env.NODE_ENV == 'development') {
    Openid = store.state.Openid;
  } else {
    Openid = sessionStorage.getItem("Openid");
    // Openid = 'oPdXo6bWGyU-0FlPAtmcA0doANzE';
    if (Openid) {
      store.commit('updateOpenid', Openid);
    }
  }

  let userInfo = store.state.userInfo;
  let Token = storage.getStore("Token");
  let isLogin = store.state.isLogin;
  if (!Openid && to.meta.isRoutes && to.path != '/authorize') {
    if (to.path != '/test' && to.path != '/openJNJUser') {
      storage.setStore('scope', 'snsapi_base');
      next({
        path: '/authorize',
        query: { scope: 'snsapi_base' }
      })
    } else {
      next();
    }
  } else {
    if (!userInfo || !isLogin || !Token) {
      Vue.prototype.$axios.post('/Api/Api/Web/GetWxUser/GetCustmSta?OpenID=' + Openid).then(res => {
        if (res.RetCode == '10000') {
          let userInfo = res.Data;
          if (userInfo) {
            store.dispatch('setUserInfo', { userInfo: userInfo });
            store.dispatch('setLoginStatus', true);
            if (res.Data.Token) {
              storage.setStore('Token', res.Data.Token);
            }
          }
          next();
        } else {
          if (to.meta.role) {
            if (isLogin) {
              next();
            } else {
              Vue.prototype.$dialog.confirm({
                title: '提示',
                message: '您当前还未注册，请先去注册',
              }).then(() => {
                sessionStorage.setItem("redirectUrl", to.fullPath);
                next('/entrance');
              })
            }
          } else {
            next();
          }
        }
      })
    } else {
      if (to.meta.role) {
        if (!store.state.isLogin) {
          Vue.prototype.$dialog.confirm({
            title: '提示',
            message: '您当前还未注册，请先去注册',
          }).then(() => {
            sessionStorage.setItem("redirectUrl", to.fullPath);
            next('/entrance');
          })
        } else if (!storage.getStore("Token")) {
          Vue.prototype.$dialog.alert({
            title: '提示',
            message: '您当前还未登录，请先去登录',
          }).then(() => {
            sessionStorage.setItem("redirectUrl", to.fullPath);
            next('/entrance');
          });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  }
})

router.afterEach((to, from) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  } else {
    document.title = '强声视介';
  }
})

export default router;
