<template>
  <div>
    <div class="container">
      <template v-if="!landscape">
      <p class="title">强易达<sup>®</sup>血流导向密网支架型号及长度一览表（仅为部分型号）</p>
      <div class="box">
        <div class="inputBox">
          <van-field class="input" v-model="searchValue" type="number" placeholder="请输入近端血管直径" :placeholder-style="{ fontSize: '12px',color: 'red', }" readonly @click="showInput" />&nbsp;mm&nbsp;&nbsp;&nbsp;&nbsp;
          <van-field class="input" v-model="searchValue1" type="number" placeholder="请输入远端血管直径" :placeholder-style="{ fontSize: '12px' }" readonly @click="showInput" />&nbsp;mm
        </div>
        <div class="crCode">
          <p>010575-241213</p>
          <p>专业内容，仅供医疗专业人士参考，严禁翻印及传播</p>
        </div>
      </div>
      <div class="table_header">
        <div class="td">规格</div>
        <div class="td">
          <div>名义态血管直径</div>
          <div class="flex_header">
            <span>长度/mm</span>
            <span>短缩率</span>
          </div>
        </div>
        <div class="td">
          <div>名义态血管直径-0.25</div>
          <div class="flex_header">
            <span>长度/mm</span>
            <span>短缩率</span>
          </div>
        </div>
        <div class="td">
          <div>名义态血管直径-0.5</div>
          <div class="flex_header">
            <span>长度/mm</span>
            <span>短缩率</span>
          </div>
        </div>
        <div class="td">装配长度</div>
      </div>
      <div class="table_body">
        <div class="table_body_tr" :class="{'bg': item.bg, 'active': item.active}" :ref="'tr'+ index" v-for="(item, index) in data" :key="index">
          <div class="td">{{ item.specifications }}</div>
          <div class="td flex_td">
            <span>{{ item.length1 }}</span>
            <span>{{ item.shortageRate1 }}</span>
          </div>
          <div class="td flex_td">
            <span>{{ item.length2 }}</span>
            <span>{{ item.shortageRate2 }}</span>
          </div>
          <div class="td flex_td">
            <span>{{ item.length3 }}</span>
            <span>{{ item.shortageRate3 }}</span>
          </div>
          <div class="td">{{ item.assemblyLength }}</div>
        </div>
        <p class="statement">声明：本工具仅供医疗专业人士使用，计算结果仅供医疗专业人士参考，最终以医疗专业人士的临床决策为准。</p>
      </div>
      </template>
      <div class="tips" v-else>
        <img src="@/assets/images/rotate.png" />
        <p>请锁屏后横屏观看</p>
      </div>
    </div>
    <van-dialog v-model="show" title="近/远端血管直径" show-cancel-button @confirm="onConfirm">
      <div class="dialogInputBox">
        <div class="inputbox">
          <van-field class="dialogInput" v-model="dialogInput" type="number" placeholder="请输入近端血管直径（范围:1.50~7.00）" />&nbsp;mm
        </div>
        <div class="inputbox">
          <van-field class="dialogInput" v-model="dialogInput1" type="number" placeholder="请输入远端血管直径（范围:1.50~7.00）" />&nbsp;mm
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: 'ChoydarTool',
  data() {
    return {
      landscape: false,
      show: false,
      searchValue: '',
      searchValue1: '',
      dialogInput: '',
      dialogInput1: '',
      data: [{
        specifications: 'FD200-13',
        length1: '13',
        shortageRate1: '21.2%',
        length2: '13.5',
        shortageRate2: '18.2%',
        length3: '14.5',
        shortageRate3: '12.1%',
        assemblyLength: '16.5',
        value: 200,
        bg: false
      },
      {
        specifications: 'FD200-15',
        length1: '15',
        shortageRate1: '21.1%',
        length2: '16',
        shortageRate2: '15.8%',
        length3: '17',
        shortageRate3: '10.5%',
        assemblyLength: '19',
        value: 200,
        bg: false
      },
      {
        specifications: 'FD200-20',
        length1: '20',
        shortageRate1: '23.1%',
        length2: '22',
        shortageRate2: '15.4%',
        length3: '23',
        shortageRate3: '11.5%',
        assemblyLength: '26',
        value: 200,
        bg: false
      },
      {
        specifications: 'FD250-15',
        length1: '15',
        shortageRate1: '28.6%',
        length2: '17',
        shortageRate2: '19.0%',
        length3: '18',
        shortageRate3: '14.3%',
        assemblyLength: '21',
        value: 250,
        bg: true
      },
      {
        specifications: 'FD250-20',
        length1: '20',
        shortageRate1: '31.0%',
        length2: '23',
        shortageRate2: '20.7%',
        length3: '24',
        shortageRate3: '17.2%',
        assemblyLength: '29',
        value: 250,
        bg: true
      },
      {
        specifications: 'FD250-25',
        length1: '25',
        shortageRate1: '30.6%',
        length2: '29',
        shortageRate2: '19.4%',
        length3: '30',
        shortageRate3: '16.7%',
        assemblyLength: '36',
        value: 250,
        bg: true
      },
      {
        specifications: 'FD300-15',
        length1: '15',
        shortageRate1: '43.4%',
        length2: '18.5',
        shortageRate2: '30.2%',
        length3: '20',
        shortageRate3: '24.5%',
        assemblyLength: '26.5',
        value: 300,
        bg: false
      },
      {
        specifications: 'FD300-20',
        length1: '20',
        shortageRate1: '43.7%',
        length2: '24',
        shortageRate2: '32.4%',
        length3: '27',
        shortageRate3: '23.9%',
        assemblyLength: '35.5',
        value: 300,
        bg: false
      },
      {
        specifications: 'FD300-25',
        length1: '25',
        shortageRate1: '40.5%',
        length2: '28.5',
        shortageRate2: '32.1%',
        length3: '31',
        shortageRate3: '26.2%',
        assemblyLength: '42',
        value: 300,
        bg: false
      },
      {
        specifications: 'FD300-30',
        length1: '30',
        shortageRate1: '16.7%',
        length2: '35',
        shortageRate2: '2.8%',
        length3: '38',
        shortageRate3: '-5.6%',
        assemblyLength: '36',
        value: 300,
        bg: false
      },
      {
        specifications: 'FD300-35',
        length1: '35',
        shortageRate1: '38.6%',
        length2: '39',
        shortageRate2: '31.6%',
        length3: '42.5',
        shortageRate3: '25.4%',
        assemblyLength: '57',
        value: 300,
        bg: false
      },
      {
        specifications: 'FD325-20',
        length1: '20',
        shortageRate1: '39.4%',
        length2: '22',
        shortageRate2: '33.3%',
        length3: '24',
        shortageRate3: '27.3%',
        assemblyLength: '33',
        value: 325,
        bg: true
      },
      {
        specifications: 'FD325-25',
        length1: '25',
        shortageRate1: '41.9%',
        length2: '29',
        shortageRate2: '32.6%',
        length3: '32',
        shortageRate3: '25.6%',
        assemblyLength: '43',
        value: 325,
        bg: true
      },
      {
        specifications: 'FD325-30',
        length1: '30',
        shortageRate1: '45.5%',
        length2: '35',
        shortageRate2: '36.4%',
        length3: '39',
        shortageRate3: '29.1%',
        assemblyLength: '55',
        value: 325,
        bg: true
      },
      {
        specifications: 'FD350-15',
        length1: '15',
        shortageRate1: '46.4%',
        length2: '18',
        shortageRate2: '35.7%',
        length3: '20',
        shortageRate3: '28.6%',
        assemblyLength: '28',
        value: 350,
        bg: false
      },
      {
        specifications: 'FD350-20',
        length1: '20',
        shortageRate1: '46.7%',
        length2: '25',
        shortageRate2: '33.3%',
        length3: '27',
        shortageRate3: '28.0%',
        assemblyLength: '37.5',
        value: 350,
        bg: false
      },
      {
        specifications: 'FD350-25',
        length1: '25',
        shortageRate1: '43.8%',
        length2: '29',
        shortageRate2: '34.8%',
        length3: '32',
        shortageRate3: '28.1%',
        assemblyLength: '44.5',
        value: 350,
        bg: false
      },
      {
        specifications: 'FD350-30',
        length1: '30',
        shortageRate1: '44.4%',
        length2: '15',
        shortageRate2: '72.2%',
        length3: '38',
        shortageRate3: '29.6%',
        assemblyLength: '54',
        value: 350,
        bg: false
      },
      {
        specifications: 'FD350-35',
        length1: '35',
        shortageRate1: '43.5%',
        length2: '40',
        shortageRate2: '35.5%',
        length3: '44',
        shortageRate3: '29.0%',
        assemblyLength: '62',
        value: 350,
        bg: false
      },
      {
        specifications: 'FD375-15',
        length1: '15',
        shortageRate1: '50.0%',
        length2: '18.5',
        shortageRate2: '38.3%',
        length3: '21',
        shortageRate3: '30.0%',
        assemblyLength: '30',
        value: 375,
        bg: true
      },
      {
        specifications: 'FD375-20',
        length1: '20',
        shortageRate1: '42.9%',
        length2: '22',
        shortageRate2: '37.1%',
        length3: '24',
        shortageRate3: '31.4%',
        assemblyLength: '35',
        value: 375,
        bg: true
      },
      {
        specifications: 'FD375-25',
        length1: '25',
        shortageRate1: '45.7%',
        length2: '28.5',
        shortageRate2: '38.0%',
        length3: '32',
        shortageRate3: '30.4%',
        assemblyLength: '46',
        value: 375,
        bg: true
      },
      {
        specifications: 'FD375-30',
        length1: '30',
        shortageRate1: '46.4%',
        length2: '34',
        shortageRate2: '39.3%',
        length3: '39',
        shortageRate3: '30.4%',
        assemblyLength: '56',
        value: 375,
        bg: true
      },
      {
        specifications: 'FD375-35',
        length1: '35',
        shortageRate1: '48.1%',
        length2: '41',
        shortageRate2: '39.3%',
        length3: '47',
        shortageRate3: '30.4%',
        assemblyLength: '67.5',
        value: 375,
        bg: true
      },
      {
        specifications: 'FD375-45',
        length1: '45',
        shortageRate1: '48.0%',
        length2: '53',
        shortageRate2: '38.7%',
        length3: '60',
        shortageRate3: '30.6%',
        assemblyLength: '86.5',
        value: 375,
        bg: true
      },
      {
        specifications: 'FD400-15',
        length1: '15',
        shortageRate1: '40.0%',
        length2: '16',
        shortageRate2: '36.0%',
        length3: '18',
        shortageRate3: '28.0%',
        assemblyLength: '25',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD400-20',
        length1: '20',
        shortageRate1: '42.0%',
        length2: '22',
        shortageRate2: '36.2%',
        length3: '24',
        shortageRate3: '30.4%',
        assemblyLength: '34.5',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD400-25',
        length1: '25',
        shortageRate1: '46.2%',
        length2: '29',
        shortageRate2: '37.6%',
        length3: '32',
        shortageRate3: '31.2%',
        assemblyLength: '46.5',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD400-30',
        length1: '30',
        shortageRate1: '45.5%',
        length2: '34',
        shortageRate2: '38.2%',
        length3: '38',
        shortageRate3: '30.9%',
        assemblyLength: '55',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD400-35',
        length1: '35',
        shortageRate1: '46.2%',
        length2: '41',
        shortageRate2: '36.9%',
        length3: '45',
        shortageRate3: '30.8%',
        assemblyLength: '65',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD400-45',
        length1: '45',
        shortageRate1: '43.8%',
        length2: '50',
        shortageRate2: '37.5%',
        length3: '55',
        shortageRate3: '31.3%',
        assemblyLength: '80',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD400-55',
        length1: '55',
        shortageRate1: '47.6%',
        length2: '63',
        shortageRate2: '40.0%',
        length3: '69',
        shortageRate3: '34.3%',
        assemblyLength: '105',
        value: 400,
        bg: false
      },
      {
        specifications: 'FD425-20',
        length1: '20',
        shortageRate1: '45.9%',
        length2: '23',
        shortageRate2: '37.8%',
        length3: '25',
        shortageRate3: '32.4%',
        assemblyLength: '37',
        value: 425,
        bg: true
      },
      {
        specifications: 'FD425-25',
        length1: '25',
        shortageRate1: '45.7%',
        length2: '28',
        shortageRate2: '39.1%',
        length3: '30',
        shortageRate3: '34.8%',
        assemblyLength: '46',
        value: 425,
        bg: true
      },
      {
        specifications: 'FD425-30',
        length1: '30',
        shortageRate1: '49.2%',
        length2: '36',
        shortageRate2: '39.0%',
        length3: '39',
        shortageRate3: '33.9%',
        assemblyLength: '59',
        value: 425,
        bg: true
      },
      {
        specifications: 'FD425-35',
        length1: '35',
        shortageRate1: '47.8%',
        length2: '41',
        shortageRate2: '38.8%',
        length3: '45',
        shortageRate3: '32.8%',
        assemblyLength: '67',
        value: 425,
        bg: true
      },
      {
        specifications: 'FD450-20',
        length1: '20',
        shortageRate1: '45.2%',
        length2: '21',
        shortageRate2: '42.5%',
        length3: '24',
        shortageRate3: '34.2%',
        assemblyLength: '36.5',
        value: 450,
        bg: false
      },
      {
        specifications: 'FD450-25',
        length1: '25',
        shortageRate1: '46.8%',
        length2: '27.5',
        shortageRate2: '41.5%',
        length3: '31',
        shortageRate3: '34.0%',
        assemblyLength: '47',
        value: 450,
        bg: false
      },
      {
        specifications: 'FD450-30',
        length1: '30',
        shortageRate1: '47.4%',
        length2: '33',
        shortageRate2: '42.1%',
        length3: '37',
        shortageRate3: '35.1%',
        assemblyLength: '57',
        value: 450,
        bg: false
      },
      {
        specifications: 'FD450-35',
        length1: '35',
        shortageRate1: '48.5%',
        length2: '38',
        shortageRate2: '44.1%',
        length3: '44',
        shortageRate3: '35.3%',
        assemblyLength: '68',
        value: 450,
        bg: false
      },
      {
        specifications: 'FD450-45',
        length1: '45',
        shortageRate1: '48.3%',
        length2: '50',
        shortageRate2: '42.5%',
        length3: '57',
        shortageRate3: '34.5%',
        assemblyLength: '87',
        value: 450,
        bg: false
      },
      {
        specifications: 'FD450-55',
        length1: '55',
        shortageRate1: '56.0%',
        length2: '61',
        shortageRate2: '51.2%',
        length3: '70',
        shortageRate3: '44.0%',
        assemblyLength: '125',
        value: 450,
        bg: false
      },
      {
        specifications: 'FD475-20',
        length1: '20',
        shortageRate1: '50.0%',
        length2: '24',
        shortageRate2: '40.0%',
        length3: '25',
        shortageRate3: '37.5%',
        assemblyLength: '40',
        value: 475,
        bg: true
      },
      {
        specifications: 'FD475-25',
        length1: '25',
        shortageRate1: '51.0%',
        length2: '30',
        shortageRate2: '41.2%',
        length3: '31',
        shortageRate3: '39.2%',
        assemblyLength: '51',
        value: 475,
        bg: true
      },
      {
        specifications: 'FD475-30',
        length1: '30',
        shortageRate1: '52.4%',
        length2: '37',
        shortageRate2: '41.3%',
        length3: '39',
        shortageRate3: '38.1%',
        assemblyLength: '63',
        value: 475,
        bg: true
      },
      {
        specifications: 'FD475-35',
        length1: '35',
        shortageRate1: '52.1%',
        length2: '43',
        shortageRate2: '41.1%',
        length3: '46',
        shortageRate3: '37.0%',
        assemblyLength: '73',
        value: 475,
        bg: true
      },
      {
        specifications: 'FD475-45',
        length1: '45',
        shortageRate1: '53.1%',
        length2: '56',
        shortageRate2: '41.7%',
        length3: '59.5',
        shortageRate3: '38.0%',
        assemblyLength: '96',
        value: 475,
        bg: true
      },
      {
        specifications: 'FD500-25',
        length1: '25',
        shortageRate1: '50.0%',
        length2: '28',
        shortageRate2: '44.0%',
        length3: '31',
        shortageRate3: '38.0%',
        assemblyLength: '50',
        value: 500,
        bg: false
      },
      {
        specifications: 'FD500-30',
        length1: '30',
        shortageRate1: '50.0%',
        length2: '33',
        shortageRate2: '45.0%',
        length3: '38',
        shortageRate3: '36.7%',
        assemblyLength: '60',
        value: 500,
        bg: false
      },
      {
        specifications: 'FD500-35',
        length1: '35',
        shortageRate1: '54.5%',
        length2: '43',
        shortageRate2: '44.2%',
        length3: '49',
        shortageRate3: '36.4%',
        assemblyLength: '77',
        value: 500,
        bg: false
      },
      {
        specifications: 'FD500-45',
        length1: '45',
        shortageRate1: '55.0%',
        length2: '55',
        shortageRate2: '45.0%',
        length3: '63',
        shortageRate3: '37.0%',
        assemblyLength: '100',
        value: 500,
        bg: false
      },
      {
        specifications: 'FD550-25',
        length1: '25',
        shortageRate1: '46.8%',
        length2: '26.5',
        shortageRate2: '43.6%',
        length3: '29',
        shortageRate3: '38.3%',
        assemblyLength: '47',
        value: 550,
        bg: true
      },
      {
        specifications: 'FD600-30',
        length1: '30',
        shortageRate1: '48.3%',
        length2: '34',
        shortageRate2: '41.4%',
        length3: '37',
        shortageRate3: '36.2%',
        assemblyLength: '58',
        value: 600,
        bg: false
      },
      {
        specifications: 'FD600-35',
        length1: '35',
        shortageRate1: '49.3%',
        length2: '41',
        shortageRate2: '40.6%',
        length3: '45',
        shortageRate3: '34.8%',
        assemblyLength: '69',
        value: 600,
        bg: false
      },
      {
        specifications: 'FD600-45',
        length1: '45',
        shortageRate1: '49.4%',
        length2: '52',
        shortageRate2: '41.6%',
        length3: '56',
        shortageRate3: '37.1%',
        assemblyLength: '89',
        value: 600,
        bg: false
      },
      {
        specifications: 'FD650-55',
        length1: '55',
        shortageRate1: '49.5%',
        length2: '60',
        shortageRate2: '45.0%',
        length3: '66',
        shortageRate3: '39.4%',
        assemblyLength: '109',
        value: 650,
        bg: false
      }]
    }
  },
  activated() {
    window.addEventListener("resize", this.renderResize, false)
  },
  deactivated () {
    window.removeEventListener("resize", this.renderResize, false)
  },
  methods: {
    renderResize() {
        // 判断横竖屏
        let width = document.documentElement.clientWidth
        let height = document.documentElement.clientHeight
        if (width > height) {
          this.landscape = true;
        } else {
          this.landscape = false;
        }
    },
    showInput() {
      this.show = true;
    },
    onConfirm() {
      this.searchValue = this.dialogInput;
      this.searchValue1 = this.dialogInput1;
      this.search();
    },
    search() {
      this.data.forEach(item => {
        item.active = false;
      })

      if(this.searchValue && this.searchValue1) {
        // if((this.searchValue <= 1.5 && this.searchValue >= 7.0) || this.searchValue1 <= 1.5 && this.searchValue1 >= 7.0) {
          // this.$refs[`tr0`][0].scrollIntoView({
        //     behavior: 'smooth'
        //   });
        //   this.$dialog.alert({
        //     title: '提示',
        //     message: '血管直径（范围：1.50~7.00），您输入的值已超出范围！',
        //     getContainer: ".container"
        //   }).then(() => {});
        // } else {
          let maxValue = (this.searchValue > this.searchValue1) ? this.searchValue : this.searchValue1;
          let searchValue = maxValue * 100;
          let hasSearchValue = this.data.some(item => item.value == searchValue);
          if(hasSearchValue) {
            this.data.forEach(item => {
              if(item.value == searchValue ) {
                item.active = true;
              }
            })
          } else {
            // let leftArr = this.data.filter(item => item.value < searchValue);
            let rightArr = this.data.filter(item => item.value > searchValue);

            // if(leftArr.length > 0) {
            //   let leftValue = leftArr[leftArr.length - 1].value;
            //   this.data.forEach(item => {
            //     if(item.value == leftValue ) {
            //       item.active = true;
            //     }
            //   })
            // }

            if(rightArr.length > 0) {
              let rightValue = rightArr[0].value;
              this.data.forEach(item => {
                if(item.value == rightValue ) {
                  item.active = true;
                }
              })
            }
          }

          let index = this.data.findIndex(item => item.active == true);
          if(index != -1) {
            this.$refs[`tr${index}`][0].scrollIntoView({
              behavior: 'smooth'
            });
          }
        // }
      } else {
        // this.$dialog.alert({
        //   title: '提示',
        //   message: '请输入近端血管直径（范围:1.50~7.00）',
        //   getContainer: ".container"
        // }).then(() => {});
      }
      
      this.$forceUpdate();
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .van-field__control::-webkit-input-placeholder {
    font-size: 13px;
    font-weight: 400;
}
.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  font-size: 13px;
  .title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;

  }
  .box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .inputBox {
    flex: 1;
    display: flex;
    align-items: center;
    margin: 10px 0;
    .input {
      width: 35%;
      height: 30px;
      line-height: 30px;
      padding: 0 8px;
      border: 1px solid #dcdfe6;
      box-sizing: border-box;
      border-radius: 6px;
    }
    .search {
      width: 50px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
      background-color: #409EFF;
      border-radius: 6px;
      margin-left: 10px;
    }
  }
  .crCode {
    font-size: 10px;
    text-align: right;
  }
  .table_header {
    display: flex;
    align-items: center;
    border: 1px solid #e7e7e7;
    background-color: #F5F7FA;
    .td {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      height: 100%;
      text-align: center;
      line-height: 26px;
    }
    .td:not(:last-of-type) {
      border-right: 1px solid #e7e7e7;
    }
    .td:last-child {
      flex: none;
      width: 70px;
    }
    .td:first-child {
      flex: none;
      width: 100px;
    }
    .flex_header {
      display: flex;
      border-top: 1px solid #e7e7e7;
      span {
        flex: 1;
      }
      span:first-child {
        border-right: 1px solid #e7e7e7;
      }
    }
  }
  .table_body {
    flex: 1;
    overflow-y: scroll;
    .table_body_tr {
      display: flex;
      line-height: 28px;
      text-align: center;
      border-bottom: 1px solid #e7e7e7;
      .td {
        flex: 1;
        border-left: 1px solid #e7e7e7;
      }
      .td:last-child {
        border-right: 1px solid #e7e7e7;
      }
      .flex_td {
        display: flex;
        span {
          flex: 1;
        }
        span:first-child {
          border-right: 1px solid #e7e7e7;
        }
      }
      .td:last-child {
        flex: none;
        width: 70px;
      }
      .td:first-child {
        flex: none;
        width: 100px;
      }
    }
    .bg {
      background-color: #f0f0f0;
    }
    .active {
      background-color: #85BFFE;
    }
  }
}

.dialogInputBox {
  padding: 20px;
  .inputbox {
    width: 100%;
    display: flex;
    align-items: center;
  }
  .dialogInput {
    flex: 1;
    height: 30px;
    line-height: 30px;
    padding: 0 8px;
    border: 1px solid #dcdfe6;
    box-sizing: border-box;
    border-radius: 6px;
  }
  .inputbox:not(:last-child) {
    margin-bottom: 15px;
  }
}

.tips {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
  font-size: 10px;
  img {
    width: 40px;
    margin-bottom: 15px;
  }
}
.statement {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0 10px;
  height: 20px;
  line-height: 20px;
  box-sizing: border-box;
  font-size: 11px;
  color: #FFFFFF;
}

@media screen and (orientation: portrait) {
  .container {
    width: 100vh;
    height: 100vw;
    transform: rotate(90deg);
    transform-origin: left top;
    position: absolute;
    left: 100%;
    top: 0;
  }
}
</style>